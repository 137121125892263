<template>
  <!-- 客户订单 -->
  <div class="customer_box">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="待业务员审核" name="first"></el-tab-pane>
      <el-tab-pane label="全部订单" name="second"></el-tab-pane>
    </el-tabs>
    <div>
      <div class="customer_search_box" style="position: static;">
        <div class="search-item">
          <span>关键字</span>
          <el-input style="width: 174px;" clearable placeholder="请输入客户名称、订单编号" size="small" v-model="filterinfo.search"></el-input>
        </div>
        <div class="search-item">
          <span>创建日期</span>
          <el-date-picker
            style="width: 200px;"
            v-model="timeRange"
            type="daterange"
            size="small"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="search-item" v-if="activeName == 'second'">
          <span>订单状态</span>
          <el-select style="width: 126px;" clearable @clear="filterinfo.shopType=undefined" size="small" v-model="filterinfo.shopType" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="search-item" v-if="activeName == 'second'">
          <span>下单方式</span>
          <el-select style="width: 96px;" clearable @clear="filterinfo.attr=undefined" size="small" v-model="filterinfo.attr" placeholder="请选择">
            <el-option
              v-for="item in orderOptions"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="btn_box">
          <el-button class="btn_item" type="primary" size="small" @click="getCustomerList(1)">查询</el-button>
          <el-button class="btn_item" size="small" @click="resetClick">重置</el-button>
        </div>
      </div>
      <div class="table_mode">
        <el-table :data="tableData" v-loading="loading" @sort-change="handleSortChange" stripe style="width: 100%">
          <el-table-column prop="orderNumber" label="订单编号" min-width="180" :show-overflow-tooltip="true" align="center" fixed="left"></el-table-column>
          <el-table-column prop="customerName" label="客户名称" min-width="220" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="paid" label="订单金额" sortable="custom" min-width="110" :show-overflow-tooltip="true" align="center">
            <template slot-scope="scope">
              <span style="color: #F92B2B;">￥{{Number(scope.row.paid).toFixed(2)}}</span>
            </template>
          </el-table-column>
          <el-table-column v-if="activeName == 'second'" prop="status" label="订单状态" min-width="110" :show-overflow-tooltip="true" align="center">
            <template slot-scope="scope">
              <span :style="{'color':scope.row.colour}">{{scope.row.status}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="placeMethod" v-if="activeName == 'second'" label="下单方式" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="postDate" label="创建时间" min-width="160" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="kindNum" label="商品种类" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="goodsNum" label="商品数量" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
          <!-- <el-table-column prop="feedbackMoney" label="预估提成" sortable="custom" min-width="110" :show-overflow-tooltip="true" align="center">
            <template slot-scope="scope">
              <span style="color: #F92B2B;">￥{{Number(scope.row.feedbackMoney).toFixed(2)}}</span>
            </template>
          </el-table-column> -->
          <el-table-column prop="address" label="操作 " width="200" align="center" fixed="right">
            <template slot-scope="scope">
              <div class="table_btn">
                <!-- <div class="btn_item" @click="auditingClick(scope.row)">审核</div> -->
                <div class="btn_item" v-if="scope.row.extra == 1" @click="auditingClick(scope.row)">审核</div>
                <el-popconfirm v-if="scope.row.extra == 3" title="确定收货吗？" @onConfirm="receiveClick(scope.row)">
                  <div class="btn_item" slot="reference">收货</div>
                </el-popconfirm>
                <div class="btn_item" v-if="scope.row.extra == 2" @click="payClick(scope.row)">支付</div>
                <div class="btn_item" @click="orderDetail(scope.row)">详情</div>
                <div class="btn_item" v-if="activeName == 'second'" @click="copyDetail(scope.row)">复制订单</div>
              </div>
            </template>
          </el-table-column>
          <div slot="empty" class="nodata_box">
            <img src="@/assets/images/index/nodata.png"/>
            <span class="txt">暂无数据哦~</span>
          </div>
        </el-table>
      </div>
    </div>
    <!-- <div v-else>
      <div class="customer_search_box" style="position: static;margin-bottom: 17px;">
        <div class="search-item">
          <span>关键字</span>
          <el-input style="width: 176px;" placeholder="请输入客户名称、订单编号" size="small" v-model="filterinfo.search"></el-input>
        </div>
        <div class="search-item">
          <span>创建日期</span>
          <el-date-picker
            style="width: 218px;"
            v-model="timeRange"
            type="daterange"
            size="small"
            value-format="yyyy-MM-DD"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <div class="btn_box">
          <el-button class="btn_item" type="primary" size="small" @click="getCustomerList">查询</el-button>
          <el-button class="btn_item" size="small" @click="resetClick">重置</el-button>
        </div>
      </div>
      <div class="table_mode">
        <el-table :data="tableData" @sort-change="handleSortChange" stripe style="width: 100%">
          <el-table-column prop="orderNumber" label="订单编号" min-width="180" :show-overflow-tooltip="true" align="center" fixed="left"></el-table-column>
          <el-table-column prop="customerName" label="客户名称" min-width="220" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="paid" label="订单金额" sortable="custom" min-width="110" :show-overflow-tooltip="true" align="center">
            <template slot-scope="scope">
              <span style="color: #F92B2B;">￥{{scope.row.paid}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="postDate" label="创建时间" min-width="160" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="kindNum" label="商品种类" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="goodsNum" label="商品数量" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
          <el-table-column prop="feedbackMoney" label="预估提成" sortable="custom" min-width="110" :show-overflow-tooltip="true" align="center">
            <template slot-scope="scope">
              <span style="color: #F92B2B;">￥{{scope.row.feedbackMoney}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="操作 " width="130" align="center" fixed="right">
            <template slot-scope="scope">
              <div class="table_btn">
                <div class="btn_item">审核</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div> -->
    <div class="pagination_box">
      <el-pagination
        background
        layout="prev, pager, next, sizes, total"
        :page-sizes="[10, 20, 30, 40]"
        :page-size.sync="filterinfo.pageSize"
        :current-page.sync="filterinfo.page"
        :total="total"
        @current-change="getCustomerList"
        @size-change="getCustomerList(1)">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { handlerTableData } from '@/utils/common'
export default {
  name: 'CustomerOrder',
  data() {
    return {
      loading: false,
      activeName: 'first',
      total: 0,
      timeRange: [],
      filterinfo:{
        orderBy:'',
        page:1,
        pageSize:10,
        search: '',
        timeRange: '',
        shopType: undefined,
        attr: undefined,
      },
      orderOptions: [],
      options: [],
      tableData: [],
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.path != '/orderDetail'&&from.path != '/pay') {
        vm.activeName = 'first'
        vm.resetClick()
      }
    });
  },
  mounted(){
    // this.getCustomerList()
  },
  methods: {
    // 点击重置
    resetClick(){
      this.filterinfo = {
        orderBy:'',
        page:1,
        pageSize:10,
        search: '',
        timeRange: '',
        shopType: undefined,
        attr: undefined,
      }
      this.timeRange = []
      this.getCustomerList()
    },
    // 获取客户订单列表
    getCustomerList(num){
      if(num==1){
        this.filterinfo.page = 1
      }
      let params = this.filterinfo
      params.shopType = this.activeName == 'first'?1:params.shopType
      if(this.timeRange&&this.timeRange.length){
        params.timeRange = this.timeRange[0]+'/'+this.timeRange[1]
      }else{
        params.timeRange = ''
      }
      this.loading = true
      this.$api.pcOrderList(params).then((res) => {
        this.loading = false
        if (res.data.code === 200) {
          this.tableData = handlerTableData(res.data.data.list)
          // this.tableData = res.data.data.list
          this.options = res.data.data.status
          this.orderOptions = res.data.data.method
          this.total = res.data.data.total
        }
      });
    },
    handleSortChange({ column, prop, order }) {
      if(order == 'ascending'){
        this.filterinfo.orderBy = prop + " asc"
      }else{
        this.filterinfo.orderBy = prop + " desc"
      }
      this.getCustomerList()
    },
    handleClick(tab, event) {
      // console.log(tab, event);
      this.resetClick()
    },
    // 订单确认收货
    receiveClick(item){
      this.$api.pcConfirmReceiving({orderNumber:item.orderNumber}).then((res) => {
        this.loading = false
        if (res.data.code === 200) {
          this.getCustomerList()
          this.$toast('收货成功！');
        }
      });
    },
    // 点击订单支付
    payClick(item){
      this.$router.push('/pay?prepareOrderNumber=&orderNumber='+item.orderNumber)
    },
    // 点击订单审核
    auditingClick(item){
      this.$router.push('/orderInfo?type=audit&prepareOrderNumber='+item.orderNumber)
    },
    // 点击订单详情
    orderDetail(item){
      this.$router.push('/orderDetail?orderNumber=' + item.orderNumber)
    },
    // 复制订单
    copyDetail(item){
      this.$router.push('/customer/myCustomer?orderNumber=' + item.orderNumber)
    },
  }
};
</script>
<style lang="scss" scoped>
.customer_box{
  position: relative;

  .search-item{
    margin-bottom: 17px;
    margin-right: 11px;
  }
  .btn_box{
    margin-bottom: 17px;
  }

  ::v-deep{
    .el-range-editor.el-input__inner{
      padding-right: 0;
    }
    .el-tabs__item.is-active{
      font-size: 16px;
      color: #337CFF;
      font-weight: 600;
    }
    .el-tabs__item{
      font-size: 16px;
      color: #333333;
    }
    .el-tabs__nav-wrap::after{
      background-color: #DDDDDD;
      height: 1px;
    }
  }
}
</style>